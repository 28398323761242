import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str, Bool } from 'pinia-orm/dist/decorators';
import { DateTime } from "luxon";

export default class Employer extends Model {

    static entity = 'employer';

    @Str('') declare billing_status: string;
    @Str(null) declare created: string;
    @Num(null) declare days_to_pay: number;
    @Str(null) declare email_campaign: string;
    @Num(null) declare id: number;
    @Str('') declare invoice_type: string;
    @Num(null) declare live_training_limit: number;
    @Num(null) declare live_training_price: number;
    @Bool(false) declare meeting_review_enabled: boolean;
    @Num(null) declare meeting_review_limit: number;
    @Num(null) declare meeting_review_price: number;
    @Str('') declare name: string;

    static api() {
        return {
            async getAdmin() {
                const { data } = await axios.get(`/employer/admin`);

                const repo = useRepo(Employer);

                repo.flush();
                repo.save(data);
            },

            async liveTrainingLimitPostAdmin(liveTrainingLimit:number) {
                const { data } = await axios.post(`/employer/limit/live-training/admin`, { live_training_limit: liveTrainingLimit });

                const repo = useRepo(Employer);

                repo.flush();
                repo.save(data);
            },

            async meetingReviewLimitPostAdmin(meetingReviewEnabled:boolean,  meetingReviewLimit:number) {
                const { data } = await axios.post(`/employer/limit/meeting-review/admin`, {
                    meeting_review_enabled:meetingReviewEnabled,
                    meeting_review_limit: meetingReviewLimit
                });

                const repo = useRepo(Employer);

                repo.flush();
                repo.save(data);
            },

            // Old
            async detail() {
                const { data } = await axios.get(`/employer`);

                const repo = useRepo(Employer);

                repo.flush();
                repo.save(data);
            },

            async searchAdmin(searchTerm: string) {
                const payload = {
                    search_term: searchTerm,
                };

                const { data } = await axios.post(`/employer-admin/search`, payload);
                return data;
            },

            async saveName(name: string) {
                const payload = {
                    name,
                };

                const { data } = await axios.post(`/employer`, payload);
                useRepo(Employer).save(data);
                return data;
            },
        };
    }

    get created_date() {
        return DateTime.fromISO(this.created);
    }

    get live_training_price_string() {
        return `$${Math.round(this.live_training_price).toLocaleString('en-us', {maximumFractionDigits: 0, useGrouping: true})}`
    }

    get meeting_review_price_string() {
        return `$${Math.round(this.meeting_review_price).toLocaleString('en-us', {maximumFractionDigits: 0, useGrouping: true})}`
    }
}