<script lang="ts" setup>
  import { ref, useAttrs } from 'vue';

  interface VuelidateError {
    $message: string;
  }
  const textArea = ref();
  const emit = defineEmits<{
    (e: 'input', entry: string): void;
  }>();

  const props = defineProps<{
    label?: string;
    modelValue: string | null;
    placeholder?: string;
    errors?: VuelidateError[] | null;
  }>();
  const attrs = useAttrs();
</script>

<template>
  <label
    v-if="!!label && !errors?.[0]"
    :class="`${attrs.class} text-body-primary mb-1 block self-start text-sm font-medium`"
  >
    {{ label }}
  </label>
  <div
    v-else-if="!!errors?.[0]"
    class="mb-1 text-sm font-bold uppercase text-red-500"
  >
    {{ errors[0].$message }}
  </div>
  <slot></slot>
  <textarea
    v-bind="attrs"
    ref="textArea"
    :placeholder="placeholder"
    :value="modelValue"
    class="focus:border-brand-primary focus:ring-brand-primary mb-2 min-h-[168px] w-full rounded-lg border-[#e8ecf4] bg-white p-2.5 pl-6 text-sm placeholder:opacity-50 disabled:bg-gray-50"
    @input="(value) => emit('input', value)"
  ></textarea>
</template>

<style scoped></style>
