<script lang="ts" setup>
  import { computed } from 'vue';

  import { Button as FlowbiteButton } from '../flowbite-vue';
  import IconActionArrow from '../icons/IconActionArrow.vue';

  type TVariant =
    | 'primary'
    | 'cta'
    | 'flat'
    | 'vegas'
    | 'app-home'
    | 'link'
    | 'icon'
    | 'icon-delete';

  interface IProps {
    color?: 'brand' | 'white' | 'red' | 'grey' | 'emerald' | 'transparent';
    textColor?: string | null;
    size?: 'large' | 'medium' | 'small';
    variant?: TVariant | TVariant[];
  }
  const props = withDefaults(defineProps<IProps>(), {
    color: 'brand',
    size: 'large',
    textColor: null,
    variant: 'primary',
  });

  const baseClass =
    ' [&>*]:flex [&>*]:w-full items-center [&>span]:flex-nowrap [&>span]:whitespace-nowrap [&>span]:justify-center [&>span]:items-center [&>span]:flex [&>span]:text-center [&>span]:w-full [&>*]:leading-none';

  const colorClass = computed(() => {
    if (props.variant.includes('link')) {
      return ' !rounded-none !bg-transparent !shadow-none [&>*]:text-brand-primary';
    }

    if (props.variant.includes('icon-delete')) {
      return ' !bg-white hover:!bg-container-alert !shadow-none !border-[1px] border-container-alert warning-icon-filter';
    }

    const baseColorClass =
      ' active:!bg-brand-tertiary [&>*]:active:!text-white [&>span>svg>path]:active:!fill-white';
    const vegasClass = props.variant.includes('vegas') && ' vegas';

    if (props.variant.includes('icon')) {
      return (
        baseColorClass +
        vegasClass +
        ' !rounded-lg !bg-brand-primary hover:!bg-brand-secondary'
      );
    }

    if (props.variant === 'app-home') {
      const baseAppClass = '  !shadow-none !rounded-lg ';
      let colorClass =
        ' hover:!bg-[#A3A2FF] [&>*]:hover:!text-white [&>*]:active:!text-white !bg-brand-primary active:!bg-brand-tertiary [&>span>svg>path]:active:!fill-white';

      if (props.color === 'grey') {
        colorClass =
          ' !bg-[#E8ECF4] active:!bg-[#d8dbe3] hover:!bg-[#d8dbe3] [&>*]:text-brand-primary border-solid border-[1px] !border-[#E8ECF4]';
      }

      if(props.color === 'transparent') {
        colorClass =
            ' !bg-transparent active:!bg-[#d8dbe3] hover:!bg-[#d8dbe3] [&>*]:text-brand-primary border-solid border-[1px] !border-[#E8ECF4]';
      }

      if (props.color === 'white') {
        colorClass =
          ' !bg-white active:!bg-[#E1E6EC] hover:!bg-[#F6F8FC] [&>*]:text-body-secondary border-solid border-[1px] !border-[#E8ECF4]';
      }
      if (props.color === 'red') {
        colorClass =
          ' !bg-red-500 active:!bg-red-600 hover:!bg-red-600 [&>*]:text-white border-solid border-[1px] !border-[#E8ECF4]';
      }
      if (props.color === 'emerald') {
        colorClass =
          ' !bg-emerald-500 active:!bg-emerald-500 hover:!bg-emerald-600 [&>*]:text-white border-solid border-[1px] !border-[#E8ECF4]';
      }

      return vegasClass + baseAppClass + colorClass;
    }

    if (props.color === 'white') {
      const baseWhiteClass =
        ' !bg-white hover:!bg-brand-secondary [&>*]:hover:!text-white [&>span>svg>path]:hover:!fill-white';
      const textClass = !props.textColor
        ? ' [&>*]:text-brand-primary'
        : ` [&>*]:text-${props.textColor}`;

      return baseColorClass + vegasClass + baseWhiteClass + textClass;
    }

    if (props.color === 'transparent') {
      const baseWhiteClass =
          ' !bg-transparent hover:!bg-transparent [&>*]:hover:!text-white [&>span>svg>path]:hover:!fill-white';
      const textClass = !props.textColor
          ? ' [&>*]:text-white'
          : ` [&>*]:text-${props.textColor}`;

      return baseColorClass + vegasClass + baseWhiteClass + textClass;
    }

    return (
      baseColorClass +
      vegasClass +
      ' !bg-brand-primary [&>*]:text-white hover:!bg-white [&>*]:hover:!text-brand-primary  [&>span>svg>path]:hover:!fill-brand-primary'
    );
  });

  const sizeClass = computed(() => {
    if (props.variant.includes('link') || props.variant.includes('icon')) {
      return ' [&>*]:text-base !p-0';
    }

    if (props.variant.includes('app-home')) {
      return ' [&>*]:text-base !px-4 !py-2 [&>*]:leading-[24px]';
    }

    if (props.size === 'small') {
      return ' [&>*]:text-xs !px-4 !py-2';
    }

    if (props.size === 'medium') {
      return ' [&>*]:text-xs !px-6 !py-3';
    }

    if (props.variant?.includes('vegas')) {
      return ' [&>*]:text-base !px-[21px] !py-[13px]';
    }

    return ' [&>*]:text-base !px-6 !py-4';
  });

  const buttonClass = computed(() => {
    return baseClass + colorClass.value + sizeClass.value;
  });

  const arrowFill = computed(() => {
    if (props.textColor) {
      return props.textColor;
    }

    return props.color === 'brand' ? 'white' : 'brand-primary';
  });
</script>

<template>
  <FlowbiteButton :class="buttonClass" v-bind="$attrs">
    <slot />
    <IconActionArrow
      v-if="variant.includes('cta')"
      class="ml-2"
      :color="arrowFill"
    />
  </FlowbiteButton>
</template>

<style scoped>
  button {
    box-shadow: 0 4px 20px rgba(94, 92, 255, 0.2);
  }

  button.vegas {
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(90deg, #e3a0ff, #7482ff, #a8efff);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }

  button.vegas:hover {
    box-shadow: 2px 1000px 1px theme(colors.brand.primary) inset;
  }

  button.vegas:active {
    box-shadow: 2px 1000px 1px theme(colors.brand.tertiary) inset;
  }
</style>
