<script lang="ts" setup>
  import { computed, toRefs, useAttrs } from 'vue';

  import { usePassword, useValidationErrorMessage } from '../composables/form';
  import { Input } from '../flowbite-vue';

  interface VuelidateError {
    $message: string;
  }
  interface IProps {
    errors?: VuelidateError[] | null;
    placeholder?: string;
    label?: string;
    modelValue?: string | number;
    type: 'text' | 'password' | 'tel';
    variant?: 'primary' | 'config';
  }

  const props = withDefaults(defineProps<IProps>(), {
    errors: null,
    placeholder: '',
    label: '',
    modelValue: '',
    variant: 'primary',
  });

  const attrs = useAttrs();

  const { passwordInputType, passwordComponent, togglePasswordVisibility } =
    usePassword();
  const { label, errors } = toRefs(props);
  const { errorClass, errorMessage, inputLabel } = useValidationErrorMessage(
    label,
    errors
  );

  const variantClasses = computed(() => {
    if (props.variant === 'config') {
      return ' [&>label]:uppercase [&>label]:text-header-primary [&>label]:text-sm [&>label]:text-semibold [&>label]:opacity-50';
    }

    return '';
  });

  const inputClasses = computed(() => {
    const baseClass =
      props.variant +
      ' ' +
      attrs.class +
      variantClasses.value +
      ' !dark:bg-white disabled:!opacity-70 disabled:!bg-gray-100  [&>div>input]:!dark:bg-white [&>div>input]:dark:text-body-primary [&>label]:!mb-1 [&>div>input]:!bg-white [&>div>input]:!px-6 [&>div>input]:!py-3 [&>div>input]:!text-sm [&>div>input]:!leading-5 [&>div>div]:!right-4';

    if (errorMessage.value) {
      return baseClass + errorClass.value;
    }

    return (
      baseClass +
      ' [&>label]:text-body-primary [&>label]:dark:text-body-primary'
    );
  });

  const formType = computed(() => {
    if (props.type !== 'password') {
      return props.type;
    }

    return passwordInputType.value;
  });
</script>

<template>
  <Input
    v-bind="attrs"
    :placeholder="placeholder"
    :label="inputLabel"
    :type="formType"
    :class="inputClasses"
    :model-value="modelValue ? String(modelValue) : undefined"
  >
    <template #prefix>
      <slot name="prefix"></slot>
    </template>
    <template #suffix>
      <button
        v-if="type === 'password'"
        type="button"
        :class="variant === 'config' && 'mt-3 block'"
        @click="togglePasswordVisibility"
      >
        <component :is="passwordComponent" />
      </button>
      <slot name="suffix"></slot>
    </template>
  </Input>
</template>

<style scoped>
  :deep(input),
  :deep(input.dark) {
    border: 1px solid;
    border-color: #e8ecf4 !important;
  }

  :deep(input):focus,
  :deep(input.dark):focus {
    border-color: #5e5cff !important;
  }

  :deep(input)::placeholder,
  :deep(input.dark)::placeholder {
    opacity: 50%;
  }
</style>
