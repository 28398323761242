import axios from 'axios';
import { DateTime } from "luxon";
import { Model, useRepo } from 'pinia-orm';
import {BelongsTo, HasOne, Num, Str} from 'pinia-orm/dist/decorators';

import Learner from './learner';
import MeetingAnalysis from './meeting_analysis';


export default class MeetingReview extends Model {

    static entity = 'meeting-review';

    @HasOne(() => MeetingAnalysis, 'review_id') declare analysis: MeetingAnalysis | null;
    @Str(null) declare analyze_started: string | null;
    @Str(null) declare bot_joined: string | null;
    @Str(null) declare completed: string | null;
    @Str(null) declare created: string;
    @Str(null) declare expires: string | null;
    @Str(null) declare failed: string | null;
    @Str(null) declare failure_reason: string | null;
    @Num(null) declare id: number;
    @BelongsTo(() => Learner, 'learner_id') declare learner: Learner;
    @Num(null) declare learner_id: number;
    @Str(null) declare name: string;
    @Str(null) declare starts_at: string;
    @Str(null) declare status: string;

    static api() {
        return {
            async detailGetLearner(id:number) {
                const { data } = await axios.get(`meeting-review/${id}/learner`)

                const repo = useRepo(MeetingReview)
                repo.save(data)
            },

            async cancelDeleteLearner(id:number) {
                await axios.delete(`meeting-review/${id}/cancel/learner`)

                const repo = useRepo(MeetingReview)
                repo.destroy(id)
            },

            async expireDeleteLearner(id:number) {
                await axios.delete(`meeting-review/${id}/expire/learner`)

                const repo = useRepo(MeetingReview)
                repo.destroy(id)
            },

            async getLearner() {
                const { data } = await axios.get('meeting-review/learner')

                const repo = useRepo(MeetingReview)
                repo.flush()
                repo.save(data)
            },

            async postLearner(meetingUrl: string, startsAt:DateTime) {
                const { data } = await axios.post('meeting-review/learner', {
                    meeting_url: meetingUrl,
                    starts_at: startsAt.toISO()
                })

                const repo = useRepo(MeetingReview)
                repo.save(data)
            },

            async remainingPostLearner(startsAt:DateTime) {
                const { data } = await axios.post('meeting-review/remaining/learner', { starts_at: startsAt.toISO() })

                return data
            },
        };
    }

    get badge_date(): DateTime {
        if (this.failed !== null)
            return DateTime.fromISO(this.failed)
        else if (this.completed !== null && this.expires !== null)
            return DateTime.fromISO(this.expires)
        else if (this.analyze_started !== null)
            return DateTime.fromISO(this.analyze_started)
        else if (this.bot_joined !== null)
            return DateTime.fromISO(this.bot_joined)
        else
            return DateTime.fromISO(this.starts_at)
    }

    get badge_text(): string {
        if (this.failed !== null)
            return `Failed ${this.badge_date.toRelative()}`
        else if (this.completed !== null && this.expires !== null)
            return `Expires ${this.badge_date.toRelative()}`
        else if (this.analyze_started !== null)
            return `Started ${this.badge_date.toRelative()}`
        else if (this.bot_joined !== null)
            return `Started ${this.badge_date.toRelative()}`
        else
            return this.badge_date.toFormat('MMM d t ZZZZ')
    }

    get badge_type(): 'error' | 'info' | 'pass' | 'warn' {
        if (this.failed !== null)
            return 'error'
        else if (this.completed !== null && this.expires !== null) {
            const compareDays = this.badge_date.diffNow('days').days
            if (compareDays <= 7)
                return 'error'
            else if (compareDays <= 14)
                return 'warn'
            return 'pass'
        }

        return 'info'
    }

    get failure_reason_title(): string | null {
        if (this.failure_reason === 'account_suspended')
            return  'Account Suspended'
        else if (this.failure_reason === 'analysis_failed')
            return 'Analysis Failed'
        else if (this.failure_reason === 'limit_reached')
            return 'Limit Reached'
        else if (this.failure_reason === 'meeting_too_short')
            return 'Meeting Too Short'
        else if (this.failure_reason === 'recording_failed')
            return 'Recording Failed'
        else if (this.failure_reason === 'recording_permission_denied')
            return 'Recording Permission Denied'
        else if (this.failure_reason === 'system_error')
            return 'System Error'
        else if (this.failure_reason === 'transcription_error')
            return 'Transcription Error'

        return null
    }

    get starts_at_date(): DateTime {
        return DateTime.fromISO(this.starts_at)
    }
}
