import axios from 'axios';
import { defineStore } from 'pinia';
import { useRepo } from 'pinia-orm';
import { posthog } from 'posthog-js';

import { Assignment, AssignmentAuction, AssignmentAuctionBid, Employer, Learner, MeetingReview, PaymentMethod, Skill, Usage, User,} from '@orm-lib';

import { useResetStore } from '@/services/resetPinia';

export type TAppState = {
    admin_id: number | null;
    coach_id: number | null;
    employer_id: number | null;
    learner_id: number | null;
    token: string | null;
    user_id: number | null;
};

export const useAppStore = defineStore('app', {

    state: () =>
        <TAppState> {
            admin_id: null,
            coach_id: null,
            employer_id: null as null | number,
            learner_id: null,
            token: localStorage.getItem('token') || null,
            user_id: parseInt(localStorage.getItem('user_id') ?? '') || null,
        },

    actions: {
        async clearModel() {
            const resetStore = useResetStore();
            resetStore.all();
        },

        async clearModelState() {
            this.admin_id = null;
            this.coach_id = null;
            this.employer_id = null;
            this.learner_id = null;
        },

        async clearToken() {
            if (this.token !== null)
                posthog.reset();

            await this.clearModelState();

            this.token = null;
            this.user_id = null;

            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
        },

        async loadModel() {
            await Promise.allSettled([
                this.clearModel(),
                this.clearModelState()
            ])

            if (this.is_authenticated === false) {
                return;
            }

            // Load Model State
            const userSelf = await User.api().self()

            if (userSelf.id !== this.user_id) {
                await this.logout()
                return
            }

            if (userSelf.is_learner) {
                this.learner_id = userSelf.learner.id;
                this.employer_id = userSelf.learner.employer_id

                if (userSelf.learner.admin !== null)
                    this.admin_id = userSelf.learner.admin.id

            } else if (userSelf.is_coach) {
                this.coach_id = userSelf.coach.id
            }

            // Load Default Models
            const modelPromises = [
                () => Skill.api().list(),
            ]

            if (this.admin_id !== null) {
                modelPromises.push(() => Assignment.api().getAdmin())
                modelPromises.push(() => Employer.api().getAdmin())
                modelPromises.push(() => Learner.api().getAdmin())
                modelPromises.push(() => PaymentMethod.api().getAdmin())
                modelPromises.push(() => Usage.api().getAdmin())
            }

            if (this.learner_id !== null) {
                modelPromises.push(() => Learner.api().getLearner())
                modelPromises.push(() => MeetingReview.api().getLearner())
            } else if (this.coach_id !== null) {
                modelPromises.push(() => Assignment.api().getCoach())
                modelPromises.push(() => AssignmentAuction.api().getCoach())
                modelPromises.push(() => AssignmentAuctionBid.api().getCoach())
            }

            await Promise.allSettled(modelPromises.map(modelPromise => modelPromise()));

            // Set Up Tracking
            let emailCampaign = null;

            if (this.is_learner) {
                const employer = useRepo(Employer).find(this.employer_id)
                emailCampaign = employer.email_campaign
                posthog.group('employer', employer.id.toString(), {
                    name: employer.name,
                    email_campaign: emailCampaign
                });
            }

            const user = useRepo(User).find(this.user_id)
            posthog.identify(
                user.id.toString(), {
                    email: user.email,
                    email_campaign: emailCampaign,
                    first_name: user.first_name,
                    last_name: user.last_name
                }
            )
        },

        async login(email: string, password: string) {
            await this.clearToken();

            const tokenResponse = await axios.post('/token', {
                username:email,
                password:password,
            });

            this.token = tokenResponse.data.token;
            this.user_id = tokenResponse.data.user.id;

            localStorage.setItem('token', this.token);
            localStorage.setItem('user_id', this.user_id)

            await this.loadModel();
        },

        async logout() {
            try {
                await axios.delete('/token');
            } catch (e) {
                console.log(e)
            }

            await Promise.allSettled([
                this.clearToken(),
                this.clearModel(),
            ]);
        },
    },

    getters: {
        is_admin(state) {
            return state.admin_id !== null;
        },

        is_authenticated(state) {
            if (state.token !== null && state.token !== undefined) {
                if (state.user_id !== null && state.user_id !== undefined) {
                    return true
                }
            }

            return false
        },

        is_coach(state): boolean {
            return state.coach_id !== null;
        },

        is_learner(state): boolean {
            return state.learner_id !== null;
        },
    },
});