import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num } from 'pinia-orm/dist/decorators';

import Employer from "./employer";


export default class ReportSummary extends Model {

    static entity = 'report-summary';
    static primaryKey = 'employer_id'

    @Num(0) declare assignments_started: number;
    @BelongsTo(() => Employer, 'employer_id') declare employer: Employer | null;
    @Num(null) declare employer_id: number | null;
    @Num(0) declare meeting_reviews_scheduled: number;
    @Num(null) declare skill_competence_from: number | null;
    @Num(null) declare skill_competence_to: number | null;

    static api() {
        return {
            async getAdmin() {
                const { data } = await axios.get('/report-summary/admin');

                const repo = useRepo(ReportSummary);
                repo.fresh(data);
            },
        };
    }
}