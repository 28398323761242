import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingObjective from './meeting_objective';
import MeetingSuggestion from './meeting_suggestion';


export default class MeetingExample extends Model {

    static entity = 'meeting-example';

    @Str(null) declare created: string;
    @Str(null) declare description: string;
    @Num(null) declare id: number;
    @BelongsTo(() => MeetingObjective, 'objective_id') declare objective: MeetingObjective;
    @Num(null) declare objective_id: number;
    @Num(null) declare suggestion_id: number;
    @BelongsTo(() => MeetingSuggestion, 'suggestion_id') declare suggestion: MeetingSuggestion;

}