import { Model } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import AssessmentAnswer from './assessment_answer';

export default class AssessmentQuestion extends Model {
  static entity = 'assessment-question';

  @Num(null) declare id: number;
  @HasMany(() => AssessmentAnswer, 'question_id')
  declare answers: AssessmentAnswer[];
  @Num(null) declare assessment_id: number;
  @Num(null) declare order: number;
  @Str('') declare title: string;
  @Str('') declare type: string;
}
