import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Coach from './coach';
import Engagement from './engagement';
import Learner from './learner';
import Skill from './skill';


export default class Assignment extends Model {

    static entity = 'assignment';

    @Str(null) declare assignment_expires: string;
    @BelongsTo(() => Coach, 'coach_id') declare coach: Coach | null;
    @Num(null) declare coach_id: number;
    @Str(null) declare created: string;
    @BelongsTo(() => Engagement, 'engagement_id') declare engagement: Engagement | null;
    @Num(null) declare engagement_id: number | null;
    @Str(null) declare evaluation_expires: string;
    @Num(null) declare id: number;
    @BelongsTo(() => Learner, 'learner_id') declare learner: Learner;
    @Num(null) declare learner_id: number;
    @Str(null) declare matched: string | null;
    @Str(null) declare matches: string | null;
    @BelongsTo(() => Skill, 'skill_id') declare skill: Skill | null;
    @Num(null) declare skill_id: number | null;
    @Str(null) declare started: string | null;
    @Num(null) declare start_competence: number | null;
    @Str(null) declare status: string;

    static api() {
        return {

            async completePutCoach(assignmentId:number) {
                await axios.put(`assignment/${assignmentId}/complete/coach`)

                const repo = useRepo(Assignment)
                repo.destroy(assignmentId)
            },

            async detailDeleteAdmin(assignmentId:number) {
                await axios.delete(`/assignment/${assignmentId}/admin`);

                const repo = useRepo(Assignment)
                repo.destroy(assignmentId)
            },

            async evaluatePutLearner(assignmentId:number, competence: number, trainerMatch:number, comments: string | null) {
                const { data } = await axios.put(`assignment/${assignmentId}/evaluate/learner`, {
                    comments: comments,
                    competence: competence,
                    trainer_match: trainerMatch,
                })

                const repo = useRepo(Learner)
                repo.save(data)
            },

            async getAdmin() {
                const { data } = await axios.get('/assignment/admin');

                const repo = useRepo(Assignment);
                repo.flush();
                repo.save(data);
            },

            async getCoach() {
                const { data } = await axios.get('/assignment/coach');

                const repo = useRepo(Assignment);
                repo.flush();
                repo.save(data);
            },

            async postAdmin(assignments:object[]) {
                const { data } = await axios.post('assignment/admin', assignments)

                const repo = useRepo(Assignment)
                repo.save(data)
            },

            async startPutLearner(assignmentId:number, skillId: number, timezone:string, competence: number) {
                const { data } = await axios.put(`assignment/${assignmentId}/start/learner`, {
                    skill_id: skillId,
                    timezone: timezone,
                    competence: competence,
                })

                const repo = useRepo(Assignment)
                repo.save(data)
            },
        };
    }

    get assignment_expires_date() {
        return DateTime.fromISO(this.assignment_expires);
    }

    get badge_date() {
        if (this.status === 'assigned')
            return this.assignment_expires_date
        else if (this.status === 'matching')
            return this.matches_date
        else if (this.status === 'training')
            return this.matched_date
        else if (this.status === 'evaluating')
            return this.evaluation_expires_date

        return null
    }

    get badge_text() {
        let prefix = null as null | string

        if (this.status === 'assigned') {
            if (this.badge_date.diffNow('hours').hours < 0)
                return 'Expires Today'

            prefix = 'Expires '
        } else if (this.status === 'matching') {
            prefix = 'Completes '
        } else if (this.status === 'training') {
            prefix = 'Started '
        } else if (this.status === 'evaluating') {
            if (this.badge_date.diffNow('hours').hours < 0)
            return 'Expires Today'

            prefix = 'Expires '
        }

        return `${prefix} ${this.badge_date.toRelative()}`
    }

    get badge_type() {
        const compareDays = Math.ceil(this.badge_date.diffNow('days').days)
        const compareMonths = this.badge_date.diffNow('months').months

        if (this.status === 'assigned') {
            if (compareDays < 7)
                return 'error'
            else if (compareDays < 14)
                return 'warn'
        } else if (this.status === 'training') {
            if (compareMonths < -3)
                return 'error'
            else if (compareMonths < -2)
                return 'warn'
        } else if (this.status === 'evaluating') {
            if (compareDays < 7)
                return 'error'
            else if (compareDays < 14)
                return 'warn'
        }

        return 'pass'
    }

    get created_date() {
        return DateTime.fromISO(this.created)
    }

    get evaluation_expires_date() {
        return DateTime.fromISO(this.evaluation_expires)
    }

    get matched_date() {
        return DateTime.fromISO(this.matched)
    }

    get matches_date() {
        return DateTime.fromISO(this.matches)
    }

    get started_date() {
        return DateTime.fromISO(this.started)
    }
}