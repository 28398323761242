import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import AssessmentQuestion from './assessment_question';

export default class Assessment extends Model {
  static entity = 'assessment';

  @Num(null) declare id: number;
  @HasMany(() => AssessmentQuestion, 'assessment_id')
  declare questions: AssessmentQuestion[];
  @Str('') declare type: string;
  @Num(null) declare version: number;

  static api() {
    return {
      async list(type = 'cohort') {
        const { data } = await axios.get(`/assessment/${type}`);
        const repo = useRepo(Assessment);
        repo.flush();
        repo.save(data);
      },
    };
  }
}
