import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Coach from './coach';
import Organization from './organization';

export default class OrganizationAdmin extends Model {
  static entity = 'organizationAdmin';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @BelongsTo(() => Coach, 'coach_id')
  declare coach: Coach;
  @Num(null) declare coach_id: number;
  @BelongsTo(() => Organization, 'organization_id')
  declare organization: Organization;
  @Num(null) declare organization_id: number;

  static api() {
    return {
      async add(coach_id: number) {
        const payload = {
          coach_id: coach_id,
        };
        const { data } = await axios.post(`/organization-admin`, payload);

        useRepo(OrganizationAdmin).save(data);
      },
      async remove(id: number) {
        await axios.delete(`/organization-admin/${id}`);

        useRepo(OrganizationAdmin).destroy(id);
      },
      async search(searchTerm: string) {
        const payload = {
          search_term: searchTerm,
        };
        const { data } = await axios.post(
          `/organization-admin/search`,
          payload
        );

        return data;
      },
    };
  }
}
