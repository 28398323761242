<script lang="ts" setup>
  import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

  import { Modal } from '../flowbite-vue';

  interface IProps {
    closeOnClickOutside?: boolean;
    variant?: 'primary' | 'full' | 'nav';
  }
  const props = withDefaults(defineProps<IProps>(), {
    closeOnClickOutside: true,
    variant: 'primary',
  });
  const emit = defineEmits<{
    (e: 'close', trigger: string): void;
  }>();

  const modal = ref();

  function close(trigger: string) {
    if (props.variant === 'nav') {
      const element = modal.value.$el;
      element.classList.add('[&>div]:translate-x-[100%]');

      setTimeout(() => {
        emit('close', trigger);
      }, 250);
      return;
    }
    emit('close', trigger);
  }

  const modalClasses = computed(() => {
    const baseModalClasses =
      'overscroll-hidden [&>div>div]:h-auto [&>div>div>div>div]:p-0 [&>div>div>div>div:last-child]:-mt-10 [&>div>div>div>div>button]:z-50 [&>div>div>div>div>button]:mt-2 [&>div>div>div>div>button]:mr-2';

    if (props.variant === 'nav') {
      return (
        baseModalClasses +
        ' [&>div]:!block [&>div>div>div]:rounded-none [&>div>div>div>div:last-child]:-mt-12 [&>div]:translate-x-[100%] [&>div]:duration-250 [&>div]:transition [&>div]:ease-in-out [&>div]:h-full [&>div>div>div]:h-full [&>div>div]:min-w-[100vw] [&>div>div]:p-0 [&>div>div]:min-h-[100vh] [&>div>div]:bg-transparent [&>div>div>div>div>button]:mt-[18px] [&>div>div>div>div>button]:p-[8px] [&>div>div>div>div>button]:mr-[18px] [&>div>div>div>div>button>svg]:fill-black [&>div>div>div>div>button]:bg-gray-50'
      );
    }

    if (props.variant === 'full') {
      return (
        baseModalClasses +
        ' [&>div>div]:max-h-[90vh] [&>div>div]:min-w-[100vw] [&>div>div]:bg-transparent [&>div>div>div>div>button>svg]:fill-black [&>div>div>div>div>button]:bg-gray-50'
      );
    }

    return baseModalClasses + ' [&>div>div]:max-h-[90vh]';
  });

  const bodyClasses = computed(() => {
    return '[&>div.bg-white]:!bg-transparent';
  });

  onMounted(() => {
    const body = document.querySelector('body');

    const closeButton = modal.value.$el.querySelector('button.bg-transparent');

    if (closeButton) {
      closeButton.addEventListener('click', (event: Event) => {
        event.stopPropagation();
      });
      closeButton.addEventListener('touchstart', (event: Event) => {
        event.stopPropagation();
      });
      closeButton.addEventListener('touchend', (event: Event) => {
        event.stopPropagation();
      });
    }

    if (body && body.scrollHeight > window.innerHeight) {
      body.style.position = 'fixed';
      body.style.overflowY = 'scroll';
    }

    if (props.variant === 'nav') {
      const element = modal.value.$el;
      setTimeout(() => {
        element.classList.remove('[&>div]:translate-x-[100%]');
      }, 50);
    }
  });

  onBeforeUnmount(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.removeProperty('overflow-y');
      body.style.removeProperty('position');
    }
  });
</script>

<template>
  <Modal ref="modal" :class="modalClasses" @close="close('icon')">
    <template #body>
      <div
        v-if="closeOnClickOutside"
        v-click-outside="close"
        :class="bodyClasses"
      >
        <slot></slot>
      </div>
      <div v-else :class="bodyClasses">
        <slot></slot>
      </div>
    </template>
  </Modal>
</template>

<style scoped>
  :deep(.modal-container) {
    @apply mt-[-10px];
  }
</style>
