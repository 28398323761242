import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Faq extends Model {
  static entity = 'faq';

  @Str('') declare created: string;
  @Num(null) declare id: number;
  @Str('') declare question: string;
  @Str('') declare answer: string;
  @Num(null) declare order: number;

  static api() {
    return {
      async list() {
        const { data } = await axios.get('/faq');
        const repo = useRepo(Faq);
        repo.flush();
        repo.save(data);
      },
    };
  }

  static piniaOptions = {
    persist: true,
  };
}
