import { Model } from 'pinia-orm';
import { BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingObjective  from './meeting_objective';
import MeetingParticipant from './meeting_participant';
import MeetingReview from './meeting_review';
import MeetingSuggestion from './meeting_suggestion';


export default class MeetingAnalysis extends Model {

    static entity = 'meeting-analysis';

    @Str(null) declare created: string;
    @Num(null) declare id: number;
    @HasMany(() => MeetingObjective, 'analysis_id') declare objectives: MeetingObjective[];
    @HasMany(() => MeetingParticipant, 'analysis_id') declare participants: MeetingParticipant[];
    @Num(null) declare review_id: number;
    @BelongsTo(() => MeetingReview, 'review_id') declare review: MeetingReview;
    @HasMany(() => MeetingSuggestion, 'analysis_id') declare suggestions: MeetingSuggestion[];
}
