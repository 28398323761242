import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num } from 'pinia-orm/dist/decorators';

import Employer from './employer';
import Learner from './learner';

export default class EmployerAdmin extends Model {

    static entity = 'employer-admin';

    @Num(null) declare id: number;
    @BelongsTo(() => Employer, 'employer_id') declare employer: Employer;
    @Num(null) declare employer_id: number;
    @BelongsTo(() => Learner, 'learner_id') declare learner: Learner;
    @Num(null) declare learner_id: number;

    static api() {
        return {
            async postAdmin(learnerId: number) {
                const { data } = await axios.post(`/employer-admin/admin`, { learner_id: learnerId });
                const repo = useRepo(EmployerAdmin);
                repo.save(data);
            },

            async detailDeleteAdmin(employerAdminId: number) {
                await axios.delete(`/employer-admin/${employerAdminId}/admin`);
                const repo = useRepo(EmployerAdmin);
                repo.destroy(employerAdminId);
            },
        };
    }
}
