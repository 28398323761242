import { type Ref, computed, markRaw, ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { isAxiosError } from 'axios';

import IconClosedEye from '../icons/IconClosedEye.vue';
import IconOpenEye from '../icons/IconOpenEye.vue';

const defaultServiceError: string =
  'There was an issue submitting this form. Please try again.';
interface VuelidateError {
  $message: string;
}

export const useFormError = () => {
  const serviceError = ref();

  function handleError(e: unknown) {
    if (
      isAxiosError(e) &&
      e.response?.data &&
      typeof e?.response?.data === 'object' &&
      'non_field_errors' in e.response.data
    ) {
      const errors = <string[]>e.response?.data?.non_field_errors;

      serviceError.value = errors?.[0] ?? defaultServiceError;
    } else if (
      isAxiosError(e) &&
      e.response?.data &&
      typeof e?.response?.data === 'object'
    ) {
      const data = <object>e?.response?.data;
      serviceError.value = Object.values(data)?.[0]?.[0] ?? defaultServiceError;
    } else {
      serviceError.value = defaultServiceError;
    }
  }

  return {
    serviceError,
    handleError,
  };
};

export const useValidatedForm = (
  form: any,
  validations: any,
  // eslint-disable-next-line no-unused-vars
  submitHandler: (_cb?: (arg?: any) => void) => Promise<void>,
  errorHandler?: (_e: unknown) => void
) => {
  const v$ = useVuelidate(validations, form);
  const { serviceError, handleError } = useFormError();

  const isSubmitted = ref(false);
  const isSubmitting = ref(false);

  async function validate(_cb?: () => void) {
    serviceError.value = null;
    isSubmitting.value = true;
    await v$.value.$validate();

    if (await v$.value.$invalid) {
      isSubmitting.value = false;
      return;
    }

    try {
      await submitHandler(_cb);
      await v$.value.$reset();
      isSubmitting.value = false;
      isSubmitted.value = true;
    } catch (e) {
      if (errorHandler) {
        errorHandler(e);
      } else {
        handleError(e);
      }

      isSubmitting.value = false;
    }
  }

  return {
    isSubmitting,
    isSubmitted,
    serviceError,
    validate,
    v$,
  };
};

export const useValidationErrorMessage = (
  label: Ref<string>,
  errors: Ref<VuelidateError[] | null | undefined>
) => {
  const errorClass = ref(
    ' [&>label]:uppercase [&>label]:font-bold [&>label]:!opacity-70 [&>label]:!text-red-500 [&>label]:!dark:text-red-500'
  );

  const errorMessage = computed(() => {
    return errors?.value?.[0]?.$message ?? '';
  });

  const inputLabel = computed(() => {
    if (errorMessage.value) {
      return errorMessage.value;
    }

    return label.value;
  });

  return {
    errorClass,
    errorMessage,
    inputLabel,
  };
};

export const usePassword = () => {
  const passwordVisible = ref(false);

  const passwordInputType = computed(() => {
    return passwordVisible.value ? 'text' : 'password';
  });

  const passwordComponent = computed(() => {
    return passwordVisible.value
      ? markRaw(IconClosedEye)
      : markRaw(IconOpenEye);
  });

  function togglePasswordVisibility() {
    passwordVisible.value = !passwordVisible.value;
  }

  return {
    passwordComponent,
    passwordInputType,
    passwordVisible,
    togglePasswordVisibility,
  };
};
