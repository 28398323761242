import axios from 'axios';
import type { AxiosError } from "axios";
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Bool, HasOne, Num, Str } from 'pinia-orm/dist/decorators';

import Coach from './coach';
import Learner from './learner';

export default class User extends Model {

    static entity = 'user';

    @HasOne(() => Coach, 'user_id') declare coach: Coach | null;
    @Str('') declare email: string;
    @Str('') declare first_name: string;
    @Num(null) declare id: number;
    @Bool(false) declare is_coach: boolean;
    @Bool(false) declare is_learner: boolean;
    @Str(null) declare last_login: string | null;
    @Str('') declare last_name: string;
    @HasOne(() => Learner, 'user_id') declare learner: Learner | null;

    private _full_name: string | null = null;
    private _sort_name: string | null = null;
    private _initials: string | null = null;

    static api() {
        return {
            async self() {
                const { data } = await axios.get('/user');

                useRepo(User).save(data);
                return data
            },

            async passwordPutSelf(currentPassword: string, newPassword: string, confirmPassword: string) {
                await axios.put('/user/password', {
                    confirm_password: confirmPassword,
                    current_password: currentPassword,
                    new_password: newPassword,
                });
            },

            async profilePutSelf(firstName: string, lastName: string) {
                const { data } = await axios.put('/user/profile', {
                    first_name: firstName,
                    last_name: lastName,
                });

                useRepo(User).save(data)
            },

            // Old
            async forgot(email: string) {
                const data = {
                    email: email,
                };
                await axios.put(`/user/password/forgot`, data);
            },



            async reset(userId: number, token: string, password: string) {
                const data = {
                    password: password,
                    user_id: userId,
                    token: token,
                };
                await axios.put(`/user/password/reset`, data);
            },
        };
    }

    get full_name() {
        if (this._full_name === null) {

            this._full_name = '';

            if (this.first_name !== null)
                this._full_name += this.first_name + ' '

            if (this.last_name !== null)
                this._full_name += this.last_name

            this._full_name = this._full_name.trim()
        }

        return this._full_name
    }

    get last_login_date() {
        if (this.last_login === null)
            return null

        return DateTime.fromISO(this.last_login)
    }

    get sort_name() {
        if (this._sort_name === null) {

            this._sort_name = '';

            if (this.last_name !== null)
                this._sort_name += this.last_name + ' '

            if (this.first_name !== null)
                this._sort_name += this.first_name

            this._sort_name = this._sort_name.trim()
        }

        return this._sort_name
    }

    // Old
    get initials() {
        if (this._initials === null) {
            this._initials = '';

            if (this.first_name !== null)
                this._initials += this.first_name.substring(0, 1);

            if (this.last_name !== null)
                this._initials += this.last_name.substring(0, 1);
        }

        return this._initials.toUpperCase()
    }

    get short_name() {
        return `${this.first_name} ${this.last_name.charAt(0)}.`;
    }
}