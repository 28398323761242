export default [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/Home.vue'),
    },
    {
        path: '/admin/assign-training',
        name: 'AdminAssignTraining',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/AssignTraining.vue'),
    },
    {
        path: '/admin/billing',
        name: 'AdminBilling',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/Billing.vue'),
    },
    {
        path: '/admin/dashboard',
        name: 'AdminDashboard',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/Dashboard.vue'),
    },
    {
        path: '/admin/limits',
        name: 'AdminLimits',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/Limits.vue'),
    },
    {
        path: '/admin/reporting',
        name: 'AdminReporting',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/Reporting.vue'),
    },
    {
        path: '/admin/reporting/live-training-usage',
        name: 'AdminReportingLiveTrainingUsage',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/reporting/LiveTrainingUsage.vue'),
    },
    {
        path: '/admin/reporting/meeting-review-usage',
        name: 'AdminReportingMeetingReviewUsage',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/reporting/MeetingReviewUsage.vue'),
    },
    {
        path: '/admin/reporting/skill-improvement',
        name: 'AdminReportingSkillImprovement',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/reporting/SkillImprovement.vue'),
    },
    {
        path: '/admin/users',
        name: 'AdminUsers',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/admin/Users.vue'),
    },
    {
        path: '/coach/dashboard',
        name: 'CoachDashboard',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/coach/Dashboard.vue'),
    },
    {
        path: '/coach/reporting/live-training',
        name: 'CoachReportingLiveTraining',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/coach/reporting/LiveTraining.vue'),
    },
    {
        path: '/invite/:inviteUuid([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})/accept',
        name: 'InviteAccept',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/AcceptInvite.vue'),
        props: true,
    },
    {
        path: '/invite/:inviteUuid([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})/decline',
        name: 'InviteDecline',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/DeclineInvite.vue'),
        props: true,
    },
    {
        path: '/learner/meeting-review/:meetingReviewId(\\d+)',
        name: 'LearnerMeetingReview',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/MeetingReview.vue'),
        props: ({ params }: { params: { meetingReviewId: string; } }) => ({
            meetingReviewId: Number.parseInt(params.meetingReviewId) || 0
        })
    },
    {
        path: '/learner/my-training',
        name: 'LearnerMyTraining',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/MyTraining.vue'),
    },
    {
        path: '/learner/evaluate-training',
        name: 'LearnerEvaluateTraining',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/EvaluateTraining.vue'),
    },
    {
        path: '/learner/schedule-meeting-review',
        name: 'LearnerScheduleMeetingReview',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/ScheduleMeetingReview.vue'),
    },
    {
        path: '/learner/session/:engagementId(\\d+)/session',
        name: 'Session',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/Session.vue'),
        props: ({ params }: { params: { engagementId: string } }) => ({
            engagementId: Number.parseInt(params.engagementId) || 0,
        }),
    },
    {
        path: '/learner/start-training',
        name: 'LearnerStartTraining',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/learner/StartTraining.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/LogIn.vue'),
    },
    {
        path: '/login/forgot',
        name: 'ForgotPassword',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/ForgotPassword.vue')
    },
    {
        path: '/login/reset/:userId(\\d+)/:token(\\S+)',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/ResetPassword.vue'),
        props: ({ params }: { params: { token: string; userId: string } }) => ({
            token: params.token,
            userId: Number.parseInt(params.userId) || 0
        })
    },
    {
        path: '/logout',
        name: 'LogOut',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/LogOut.vue'),
    },
    {
        path: '/my-profile',
        name: 'MyProfile',
        component: () => import(/* webpackChunkName: "app-private" */ '@/views/private/MyProfile.vue'),
    },
    {
        path: '/sign-up',
        name: 'SignUp',
        component: () => import(/* webpackChunkName: "app-public" */ '@/views/public/SignUp.vue'),
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/'
    },
];