import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Scheduler extends Model {
  static entity = 'scheduler';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @Num(null) declare coach_id: number;
  @Str(null) declare modified: string;
}
