import { Model } from 'pinia-orm';
import { BelongsTo, HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingAnalysis from './meeting_analysis';
import MeetingExample from './meeting_example';
import Skill from './skill';


export default class MeetingSuggestion extends Model {

    static entity = 'meeting-suggestion';

    @Num(null) declare analysis_id: number;
    @BelongsTo(() => MeetingAnalysis, 'analysis_id') declare analysis: MeetingAnalysis;
    @Str(null) declare created: string;
    @Str(null) declare description: string;
    @HasMany(() => MeetingExample, 'suggestion_id') declare examples: MeetingExample[];
    @Num(null) declare id: number;
    @Str(null) declare reason: string;
    @BelongsTo(() => Skill, 'skill_id') declare skill: Skill;
    @Num(null) declare skill_id: number;
    @Str(null) declare title: string;
}
