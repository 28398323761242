import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num } from 'pinia-orm/dist/decorators';

export default class Referral extends Model {
  static entity = 'referral';

  @Num(null) declare code: number;

  static api() {
    return {
      async detail() {
        const response = await axios.get('/referral');

        if (response?.data && typeof response.data === 'string') {
          useRepo(Referral).save({ code: response.data });
        }
      },
    };
  }

  get link() {
    return `${import.meta.env.VITE_WWW_BASE_URL}/refer/${this.code}`;
  }
}
