import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Report extends Model {
  static entity = 'report';

  @Num(null) declare id: number;
  @Str('') declare created: string;
  @Num(null) declare group_goal_id: number;
  @Num(null) declare percent_complete: number;
  @Num(null) declare quarter: number;
  @Num(null) declare year: number;
}
