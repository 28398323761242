import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import type Coach from './coach';
import OrganizationAdmin from './organization_admin';

export default class Organization extends Model {
  static entity = 'organization';

  @Num(null) declare id: number;
  @HasMany(() => OrganizationAdmin, 'organization_id')
  declare admins: OrganizationAdmin[];
  @Str('') declare name: string;

  static api() {
    return {
      async detail() {
        const { data } = await axios.get(`/organization`);
        const repo = useRepo(Organization);
        repo.flush();
        repo.save(data);
      },
      async searchAdmin(searchTerm: string) {
        const payload = {
          search_term: searchTerm,
        };
        const { data } = await axios.post(
          `/organization-admin/search`,
          payload
        );

        return data;
      },
    };
  }

  isAdmin(coach: Coach) {
    return !!useRepo(OrganizationAdmin)
      .where('organization_id', this.id)
      .where('coach_id', coach.id)
      .first();
  }
}
