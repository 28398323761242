import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import {
    BelongsTo,
    HasOne,
    Num,
} from 'pinia-orm/dist/decorators';

import Assignment from './assignment';
import Employer from './employer';
import EmployerAdmin from './employer_admin';
import Invite from './invite';
import User from './user';

export default class Learner extends Model {

    static entity = 'learner';

    @Num(null) declare active_assignment_id: number | null;
    @BelongsTo(() => Assignment, 'active_assignment_id') declare active_assignment: Assignment | null;
    @HasOne(() => EmployerAdmin, 'learner_id') declare admin: EmployerAdmin | null;
    @BelongsTo(() => Employer, 'employer_id') declare employer: Employer;
    @Num(null) declare employer_id: number;
    @Num(null) declare id: number;
    @HasOne(() => Invite, 'learner_id') declare invite: Invite | null;
    @BelongsTo(() => User, 'user_id') declare user: User | null;
    @Num(null) declare self_assignment_remaining: number | null;
    @Num(null) declare user_id: number | null;

    static api() {
        return {

            async deleteDetailAdmin(learnerId:number) {
                await axios.delete(`/learner/${learnerId}/admin`);

                const repo = useRepo(Learner)
                repo.destroy(learnerId)
            },

            async getAdmin() {
                const { data } = await axios.get('learner/admin');

                const repo = useRepo(Learner)
                repo.save(data);

                const allLearnerIds:Array<number> = []
                data.forEach((row) => { allLearnerIds.push(row.id) })

                repo.where((learner) => {
                    return allLearnerIds.includes(learner.id) === false
                }).delete();
            },

            async getLearner() {
                const { data } = await  axios.get('learner/learner');

                const repo = useRepo(Learner)
                repo.save(data)
            },

            async invitePostAdmin(email:string) {
                const { data } = await axios.post(`/learner/invite/admin`, { email: email });

                const repo = useRepo(Learner);

                repo.save(data);
            },

            async reinviteGetAdmin(learnerId:number) {
                const { data } = await axios.get(`/learner/${learnerId}/reinvite/admin`);

                const repo = useRepo(Learner);

                repo.save(data);
            },

            // Old
            async detail(id: number) {
                const { data } = await axios.get(`/learner/${id}`);
                useRepo(Learner).save(data);
            },

            async list() {
                const { data } = await axios.get('/learner/');
                const repo = useRepo(Learner);
                repo.save(data);
            },

            async update(id: number, isAdmin: boolean) {
                const data = {
                    is_admin: isAdmin,
                };
                await axios.put(`/learner/${id}`, data);
            },

            async user() {
                const { data } = await axios.get(`/user/learner/`);
                useRepo(Learner).save(data);
            },

            async remove(id: number) {
                await axios.delete(`/learner/${id}`);
                const repo = useRepo(Learner);
                repo.destroy(id);
            },
        };
    }

    get full_name(): string | null {
        if (this.user_id !== null)
            return this.user.full_name

        return null
    }

    get full_name_or_email(): string {
        if (this.user_id !== null)
            return this.user.full_name
        else if (this.invite !== null)
            return this.invite.email

        return ''
    }

    get sort_name(): string {

        if (this.user_id !== null)
            return this.user.sort_name

        return this.invite.email
    }

    get email(): string {
        if (this.user_id !== null)
            return this.user.email

        return this.invite.email
    }

    // Old
    get isAdmin() {
        if (this.admin !== null)
            return true

      return false
    }

    // Old
    static async getAsOptions() {
        await Learner.api().list();

        const learners = useRepo(Learner)
            .with('user')
            .orderBy((learner) => {
                return useRepo(User).find(learner.user_id)?.last_name;
            }).get();

        const options: { value: number; text: string }[] = [];

        learners.forEach((learner) =>
            options.push({ value: learner.id, text: learner.user.full_name })
        );

        return options;
    }
}