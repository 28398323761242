import { defineStore } from 'pinia';

export const useAgoraStore = defineStore('agora', {
  state: () => ({
    encoderConfig: {
      camera: {
        high: {
          bitrateMax: 2000,
          bitrateMin: 500,
          frameRate: 24,
          height: 720,
          width: 1280,
        },
        low: {
          bitrateMax: 500,
          bitrateMin: 100,
          frameRate: 24,
          height: 360,
          width: 640,
        },
      },
      screen: {
        high: {
          bitrateMax: 1000,
          bitrateMin: 300,
          frameRate: 4,
          height: 1080,
          width: 1920,
        },
      },
    },
  }),
  getters: {},
  actions: {},
});
