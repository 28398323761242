import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Attr, BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Learner from './learner';
import Skill from './skill';

export default class Feedback extends Model {
  static entity = 'feedback';

  @Num(null) declare id: number;
  @Num(null) declare assignment_id: number;
  @Num(null) declare collaborator_id: number;
  @Str(null) declare confidence_question: string;
  @Str(null) declare created: string;
  @Str(null) declare due: string;
  @BelongsTo(() => Learner, 'learner_id')
  declare learner: Learner[];
  @Num(null) declare learner_id: number;
  @Str(null) declare modified: string;
  @Attr(null) declare previous_feedback: Feedback;
  @Num(null) declare skill_id: number;
  @Str(null) declare type: string;

  static api() {
    return {
      async list() {
        const { data } = await axios.get(`/feedback-request/`);
        const repo = useRepo(Feedback);
        repo.flush();
        repo.save(data);
      },
      async submit(id: number, payload: any) {
        await axios.put(`/feedback-request/${id}/`, payload);
      },
    };
  }

  get skill() {
    return useRepo(Skill).find(this.skill_id);
  }

  get learner_full_name() {
    if (!this.learner?.user?.first_name) {
      const learner = useRepo(Learner).withAllRecursive().find(this.learner_id);

      return `${learner?.user?.first_name} ${learner?.user?.last_name}`;
    }

    return `${this.learner.user?.first_name} ${this.learner.user?.last_name}`;
  }
}
