import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class AssessmentAnswer extends Model {
  static entity = 'assessment-answer';

  @Num(null) declare id: number;
  @Num(null) declare question_id: number;
  @Num(null) declare order: number;
  @Str('') declare subtitle: string;
  @Str('') declare title: string;
}
