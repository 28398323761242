import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str, HasOne } from 'pinia-orm/dist/decorators';

import AssignmentAuctionBid from './assignment_auction_bid';


export default class AssignmentAuction extends Model {

    static entity = 'assignment-auction';

    @HasOne(() => AssignmentAuctionBid, 'assignment_auction_id') declare bid: AssignmentAuctionBid | null;
    @Num(null) declare id: number;
    @Num(0) declare skill_id: number;
    @Str(null) declare started: string;
    @Str('') declare timezone: string;

    static api() {
        return {
            async getCoach() {
                const { data } = await axios.get('/assignment-auction/coach');

                const repo = useRepo(AssignmentAuction);
                repo.flush();
                repo.save(data);
            },
        };
    }

    get badge_text() {
        if (this.bid === null)
            return 'No Offer to Host'

        return 'Offered to Host'
    }

    get badge_type() {
        if (this.bid === null)
            return 'warn'

        return 'pass'
    }

    get started_date() {
        return DateTime.fromISO(this.started)
    }

    get timezone_title() {
        return `${this.timezone} (GMT ${DateTime.now().setZone(this.timezone).toFormat('ZZ')})`;
    }
}