import axios from 'axios';
import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

type TConnectionType =
  | 'CoachCamera'
  | 'CoachScreen'
  | 'CoachReplay'
  | 'LearnerCamera'
  | 'LearnerScreen'
  | 'RecorderInstant'
  | 'RecorderArchive';

export const ConnectionType: Record<TConnectionType, string> = Object.freeze({
  RecorderInstant: '1',
  CoachCamera: '2',
  CoachReplay: '4',
  CoachScreen: '5',
  LearnerCamera: '6',
  LearnerScreen: '8',
  RecorderArchive: '9',
});

export const CoachConnectionTypes = [
  ConnectionType.CoachCamera,
  ConnectionType.CoachReplay,
  ConnectionType.CoachScreen,
];

export const LearnerConnectionTypes = [
  ConnectionType.LearnerCamera,
  ConnectionType.LearnerScreen,
];

export class Connection extends Model {
  static entity = 'connection';

  @Num(null) declare app_id: number;
  @Str(null) declare channel: string;
  @Str(null) declare connection_type: string;
  @Str(null) declare token: string;

  static api() {
    return {
      async create(sessionId: number, connectionType: TConnectionType) {
        const { data } = await axios.post(`/connection/${sessionId}`, {
          connection_type: connectionType,
        });
        return data;
      },
      async delete(sessionId: number, connectionType: TConnectionType) {
        const { data } = await axios.delete(
          `/connection/${sessionId}/${connectionType}`
        );
        return data;
      },
    };
  }
}
