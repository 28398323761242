import { ref } from 'vue';

export const usePage = (
  loaders: Promise<any>[],
  postLoad?: (_resp?: unknown) => void,
  errorHandler?: (_e: unknown) => void
) => {
  const loaded = ref(false);
  const error = ref('');

  function loadPage(response: unknown) {
    loaded.value = true;
    postLoad?.(response);
  }

  function handleError(e: unknown) {
    loaded.value = true;

    if (errorHandler) {
      return errorHandler(e);
    }

    error.value =
      'Unable to load page at this time. If this issue persists, please contact support.';

    if (e instanceof Error) {
      error.value = e.message;
    }
  }

  Promise.all(loaders).then(loadPage).catch(handleError);

  return {
    loaded,
    error,
  };
};
