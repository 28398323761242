import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Invoice extends Model {

    static entity = 'invoice';
    static primaryKey = 'number'

    @Str(null) declare created: string;
    @Str(null) declare due: string;
    @Str(null) declare number: string;
    @Str(null) declare payment_url: string;
    @Str(null) declare status: string;
    @Num(null) declare total: number;

    static api() {
        return {
            async getAdmin() {
                const { data } = await axios.get('/invoice/admin');

                const repo = useRepo(Invoice);
                repo.fresh(data)
            }
        }
    }

    get created_date() {
        return DateTime.fromISO(this.created)
    }

    get due_date() {
        return DateTime.fromISO(this.due)
    }
}