import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str, Bool } from 'pinia-orm/dist/decorators';

import Employer from "./employer";


export default class Usage extends Model {

    static entity = 'usage';

    @Num(0) declare billable_total: number;
    @BelongsTo(() => Employer, 'employer_id') declare employer: Employer | null;
    @Num(null) declare employer_id: number | null;
    @Str(null) declare id: string;
    @Num(0) declare live_training_billable_total: number;
    @Num(0) declare live_training_credits_remaining: number;
    @Num(0) declare live_training_credits_used: number;
    @Num(0) declare live_training_paid_started: number;
    @Num(0) declare live_training_started: number;
    @Num(0) declare live_training_unit_price: number;
    @Num(0) declare meeting_review_billable_total: number;
    @Num(0) declare meeting_review_credits_allocated: number;
    @Num(0) declare meeting_review_credits_completed: number;
    @Num(0) declare meeting_review_credits_remaining: number;
    @Num(0) declare meeting_review_credits_scheduled: number;
    @Num(0) declare meeting_review_paid_completed: number;
    @Num(0) declare meeting_review_paid_limit: number;
    @Num(0) declare meeting_review_paid_remaining: number;
    @Bool(false) declare meeting_review_paid_remaining_low: boolean;
    @Num(0) declare meeting_review_paid_scheduled: number;
    @Num(0) declare meeting_review_unit_price: number;
    @Num(0) declare month: number;
    @Num(0) declare year: number;


    static api() {
        return {
            async getAdmin() {
                const { data } = await axios.get('/usage/admin');

                const repo = useRepo(Usage);
                repo.flush();
                repo.save(data);
            },
        };
    }
}