import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num } from 'pinia-orm/dist/decorators';

import Note from './note';

export default class RecordingNote extends Model {
  static entity = 'recording-note';

  @Num(null) declare id: number;
  @BelongsTo(() => Note, 'note_id')
  declare note: Note[];
  @Num(null) declare note_id: number;
  @Num(null) declare recording_id: number;

  static api() {
    return {
      async create(recording_id: number, body: string, position: string) {
        const payload = {
          body: body,
          position: position,
        };

        await axios.post(`/recording/${recording_id}/note`, payload);
      },
      async remove(recording_note_id: number) {
        await axios.delete(`/recording-note/${recording_note_id}`);
      },
      async update(recording_note_id: number, body: string, position: string) {
        const data = {
          body: body,
          position: position,
        };

        await axios.put(`/recording-note/${recording_note_id}`, data);
      },
    };
  }

  static async handleModelMessage(message: {
    payload: RecordingNote;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      useRepo(RecordingNote).save(message.payload);
    } else if (message.method === 'update') {
      useRepo(RecordingNote).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(RecordingNote).destroy(message.payload.id);
      useRepo(Note).destroy(message.payload.note.id);
    }
  }
}
