<script lang="ts" setup>
import {computed, onMounted, ref, useAttrs, useSlots, watch} from 'vue';

import UiListItem from '../components/UiListItem.vue';
import {Dropdown, ListGroup, ListGroupItem} from '../flowbite-vue';

interface IProps {
  disabled?: boolean;
  options: {
    id?: string | number;
    text: string;
    subtext?: string;
    tag?: string;
    value?: string | number;
  }[];
  text?: string;
  variant?: 'form' | 'tab' | 'nav' | 'nav-white' | 'rich';
}

interface IEmits {
  (e: 'select', option: string): void;
}

const props = withDefaults(defineProps<IProps>(), {
  text: '',
  variant: 'form',
});
const dropdown = ref();
const emit = defineEmits<IEmits>();
const attrs = useAttrs();
const slots = useSlots();

const dropdownClasses = computed(() => {
  const baseClass = `dropdown [&>*>button]:min-h-[40px] [&>*>button>span]:text-left [&>*>button]:!bg-white [&>*>button]:duration-200 [&>*>button]:transition-[background,color] [&>*>button]:!px-1 [&>*>button>div>svg]:ml-0 [&>*>button>div>svg>path]:stroke-[3] ${props.variant}`;

  if (props.variant === 'tab') {
    return (
        baseClass + ' [&>*>button]:!text-[#4F56FF] [&>*>button]:font-semibold'
    );
  }

  if (props.variant.includes('nav')) {
    const navBase =
        ' [&>*>button:hover]:underline !h-[37px] [&>*>button]:mx-0 lg:[&>*>button]:mx-1 [&>*>button]:rounded [&>*>button:focus]:outline-1 [&>*>button:focus]:outline-white [&>*>button:focus]:!ring-2 [&>*>button:focus]:!ring-blue-700 lg:[&>*>button>div>svg]:!w-3 lg:[&>*>button>div]:!ml-1 lg:[&>*>button>div>svg]:!ml-0 [&>*>button]:!text-base lg:[&>*>button]:!text-sm [&>*>button]:!py-2 lg:[&>*>button]:!pl-3 lg:[&>*>button]:!pr-4 [&>*>button]:!bg-white [&>*>button]:!text-header-primary [&>*>button]:font-medium';

    if (props.variant === 'nav-white') {
      return baseClass + navBase;
    }

    return (
        baseClass +
        navBase +
        ' lg:[&>*>button]:!bg-brand-quaternary lg:[&>*>button]:!text-white '
    );
  }

  return (
      baseClass +
      ' [&>*>button]:justify-between [&>*>button]:border-[1px] [&>*>button]:!border-[#e8ecf4] [&>div]:dark:bg-transparent [&>*>button>div]:mt-[1px] [&>*>button]:!py-1 [&>*]:w-full [&>*>button]:!w-full [&>*>button]:!text-body-primary [&>*>button]:border-gray-300 [&>*>button]:!px-6 [&>*>button]:!py-3 [&>*>button]:rounded-lg focus:!ring-1 focus:!ring-blue-500 focus:border-blue-500'
  );
});

const listClasses = computed(() => {
  const baseClasses = 'dark:border-gray-200 ';

  if (props.variant === 'tab') {
    return baseClasses;
  }

  if (props.variant.includes('nav')) {
    return (
        baseClasses +
        '  text-base lg:text-sm !border-0 py-[2px] dark:!bg-transparent !bg-transparent !dark:border-0 rounded-sm [&>li:last-child]:border-b-0 [&>li:focus]:text-header-primary [&>li]:ring-offset'
    );
  }

  if (props.variant === 'rich') {
    return baseClasses + ' dark:!bg-white !w-full p-2';
  }

  return baseClasses + ' dark:!bg-white !w-full';
});

const itemClasses = computed(() => {
  const baseClasses =
      'dark:text-body-primary dark:hover:text-body-primary w-full dark:border-gray-200 dark:bg-white dark:hover:bg-gray-100 dark:hover:bg-gray-100';

  if (props.variant === 'rich') {
    return baseClasses + ' rounded-lg !border-0 !px-4';
  }

  return baseClasses;
});

const dropdownButton = computed(() => {
  return dropdown.value.$el.querySelector('button');
});

onMounted(() => {
  dropdownButton.value?.setAttribute('type', 'button');

  if (props.disabled) {
    dropdownButton.value?.classList.add('disabled:!bg-gray-100');
    dropdownButton.value?.setAttribute('disabled', 'true');
  }
});

watch(
    () => props.disabled,
    () => {
      if (props.disabled) {
        const dropdownButton = dropdown.value.$el.querySelector('button');
        dropdownButton?.classList.add('disabled:!bg-gray-100');
        dropdownButton?.setAttribute('disabled', 'true');
      } else {
        const dropdownButton = dropdown.value.$el.querySelector('button');
        dropdownButton?.classList.remove('disabled:!bg-gray-100');
        dropdownButton?.removeAttribute('disabled');
      }
    }
);
</script>

<template>
  <Dropdown
      ref="dropdown"
      :class="dropdownClasses"
      placement="bottom"
      :text="text"
      type="button"
      v-bind="attrs"
  >
    <template v-if="slots.trigger" #trigger>
      <slot name="trigger"></slot>
    </template>
    <template v-if="slots.default">
      <slot></slot>
    </template>
    <ListGroup v-else :class="listClasses">
      <ListGroupItem
          v-for="option in options"
          :key="option.id ?? option.value"
          type="button"
          tabindex="0"
          :class="itemClasses"
          @click="emit('select', option.id ?? option.value)"
          @keyup.enter="emit('select', option.id ?? option.value)"
      >
        <UiListItem
            v-if="option.subtext || variant === 'rich'"
            :text="option.text"
            :subtext="option.subtext"
            :tag="option.tag"
        />
        <template v-else>
          {{ option.text }}
        </template>
      </ListGroupItem>
    </ListGroup>
  </Dropdown>
</template>

<style scoped>
.dropdown.form :deep(ul) {
  @apply !w-full;
}
</style>
