<script lang="ts" setup>
  import { computed, useSlots } from 'vue';

  import UiAlert from './UiAlert.vue';

  interface IProps {
    title?: string;
    error?: string | null;
    success?: string | null;
    progressCurrent?: number | null;
    progressMax?: number | null;
    subtitle?: string | null;
    variant?: 'web' | 'app' | 'config' | 'inline' | 'app-config';
  }
  interface IEmits {
    (e: 'submit'): void;
  }
  const props = withDefaults(defineProps<IProps>(), {
    error: null,
    success: null,
    progressCurrent: null,
    progressMax: null,
    subtitle: null,
    variant: 'web',
  });
  const emit = defineEmits<IEmits>();
  const slots = useSlots();

  const formClass = computed(() => {
    const baseClass = `flex flex-col items-center ${props.variant}`;

    if (props.variant === 'app-config') {
      return (
        baseClass +
        ' sm:rounded-[10px] sm:border-[1px] min-w-full sm:border-solid border-[#E8ECF4] bg-white px-4 -mx-4 sm:mx-0 lg:px-6 pt-4 pb-6 md:pt-5 lg:pb-6'
      );
    }

    if (props.variant === 'config') {
      return baseClass + ' w-full rounded-[20px] bg-white p-5';
    }

    if (props.variant === 'inline') {
      return baseClass + ' w-full rounded-[20px] p-5 pb-8';
    }

    if (props.variant === 'app') {
      return (
        baseClass +
        ' w-full rounded-[20px] bg-white px-6 md:px-16 md:py-16 pt-11'
      );
    }

    return baseClass + ' w-full rounded-[20px] bg-white px-16 py-16 pt-11';
  });

  const titleClass = computed(() => {
    const baseClass = `text-header-4 text-header-primary self-start font-bold`;

    if (props.variant === 'config' || props.variant === 'inline') {
      return baseClass + ' text-brand-primary text-base mb-1.5';
    }

    if (props.variant === 'app') {
      return baseClass + ' text-brand-primary text-2xl mb-4 leading-10';
    }

    return baseClass + ' mb-2 md:self-center';
  });

  const subtitleClass = computed(() => {
    const baseSubtitleClass = 'subtitle self-start text-sm';

    if (props.variant === 'config' || props.variant === 'inline') {
      return baseSubtitleClass + ' opacity-50';
    }

    return baseSubtitleClass;
  });
</script>

<template>
  <form :class="formClass" @submit.prevent="emit('submit')">
    <h1 v-if="title" :class="titleClass">
      {{ title }}
    </h1>
    <span v-if="subtitle || slots.subtitle" :class="subtitleClass">
      <slot v-if="slots.subtitle" name="subtitle" />
      <template v-else>{{ subtitle }}</template>
    </span>
    <slot name="header"></slot>
    <UiAlert
      v-if="!!error || !!success"
      class="mb-4 w-full"
      :type="!!error ? 'danger' : 'success'"
    >
      {{ error || success }}
    </UiAlert>
    <slot></slot>
  </form>
</template>

<style scoped>
  form.config,
  form.app,
  form.web {
    box-shadow: 0 4px 30px rgba(7, 12, 54, 0.1);
  }

  .subtitle:not(:last-child) {
    @apply mb-5;
  }
</style>
