import { Model } from 'pinia-orm';
import {BelongsTo, Num, Str} from 'pinia-orm/dist/decorators';
import { Duration } from "luxon";

import MeetingAnalysis from './meeting_analysis'


export default class MeetingParticipant extends Model {

    static entity = 'meeting-participant';

    @BelongsTo(() => MeetingAnalysis, 'analysis_id') declare analysis: MeetingAnalysis;
     @Num(null) declare analysis_id: number;
     @Str(null) declare created: string;
     @Num(null) declare duration: number;
     @Num(null) declare id: number;
     @Str(null) declare name: string;


     get duration_string():string {
         return Duration.fromMillis((this.duration * 1000)).toFormat("m'M' s'S'")
     }
}
