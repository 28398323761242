import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Engagement from './engagement';
import Recording from './recording';

export default class EngagementRecording extends Model {
  static entity = 'engagement_recording';

  @Num(null) declare id: number;
  @Str(null) declare created: string;
  @BelongsTo(() => Engagement, 'engagement_id') declare engagement: Engagement;
  @Num(null) declare engagement_id: number;
  @BelongsTo(() => Recording, 'recording_id')
  declare recording: Recording;
  @Num(null) declare recording_id: number;

  static async handleModelMessage(message: {
    payload: EngagementRecording;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      await useRepo(EngagementRecording).save(message.payload);
    } else if (message.method === 'update') {
      await useRepo(EngagementRecording).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(EngagementRecording).destroy(message.payload.id);
      useRepo(Recording).destroy(message.payload.recording.id);
    }
  }
}
