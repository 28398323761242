<script lang="ts" setup>
import { ref } from 'vue';

import { useRoute } from 'vue-router';

import { useAppStore } from '@/stores';

const appStore = useAppStore();
let modelLoaded = ref(false);
const route = useRoute();

async function loadApp() {
    await appStore.loadModel();
    modelLoaded.value = true;
}

loadApp();
</script>

<template>
    <RouterView v-if="modelLoaded" :key="route.fullPath" />
</template>
