import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Bool, Num, Str } from 'pinia-orm/dist/decorators';


export default class Skill extends Model {

    static entity = 'skill';

    @Str('') declare confidence_question: string;
    @Str('') declare description: string;
    @Num(null) declare id: number;
    @Bool(false) declare is_bot_contextualized: boolean;
    @Str('') declare name: string;
    @Str('') declare tagline: string;

    static api() {
        return {
            async list() {
                const { data } = await axios.get('/skill');
                useRepo(Skill).save(data);
            },
        };
    }

    // Old
    static getAsOptions(allowAny = false, text = 'Allow the learner to choose') {

        let skillOptions = <{ value: null | number; text: string }[]>[];

        useRepo(Skill)
            .orderBy('name')
            .get()
            .forEach((skill) => {
                skillOptions.push({
                    value: skill.id,
                    text: skill.name,
                });
            });

        if (allowAny) {
            skillOptions.push({ value: null, text: text });
        }

        return skillOptions;
    }
}