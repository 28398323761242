import { nextTick } from "vue";
import { createRouter, createWebHistory } from 'vue-router';
import { useCookies } from "vue3-cookies";

import { capturePosthogPageview } from "@ui-lib";

import { useAppStore } from '@/stores';
import routes from './routes';

const base = import.meta.env.BASE_URL;
const history = createWebHistory(base);
const router = createRouter({
    history,
    routes,
});

router.beforeEach((to, from, next) => {
    // Handle pages that can be accessed regardless of authentication
    const alwaysAllowedPages = [
        'Subscription',
        'WebrtcReplay'
    ]
    if (alwaysAllowedPages.includes(<string>to.name)) {
        return next();
    }

    const appStore= useAppStore();

    // Handle pages that can only be accessed when not authenticated
    const anonOnlyPages = [
        'SignUp',
        'Login',
        'ForgotPassword',
        'InviteDecline',
        'ResetPassword',
        'InviteAccept',
    ]

    if (anonOnlyPages.includes(<string>to.name)) {
        if (appStore.is_authenticated) return next({ name: 'Home' })
        return next();
    }

    // Handle pages that can only be accessed when authenticated
    if (appStore.is_authenticated === false) {
        let query = {};
        if (to.name !== 'Home')
            query = { redirect: to.fullPath };

        return next({ name: 'Login', query });
    }

    return next();
});

router.beforeEach((to) => {
    const emailCampaignKey = import.meta.env.VITE_EMAIL_CAMPAIGN_KEY
    if (emailCampaignKey in to.query && to.query[emailCampaignKey] !== null) {
        const cookies = useCookies()
        cookies.cookies.set(emailCampaignKey, <string>to.query[emailCampaignKey])
    }
})

router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            capturePosthogPageview(to.fullPath)
        });
    }
});

export default router;
