import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Recording from './recording';

export default class SessionRecording extends Model {
  static entity = 'session_recording';

  @Num(null) declare id: number;
  @Str('') declare created: string;
  @BelongsTo(() => Recording, 'recording_id')
  declare recording: Recording[];
  @Num(null) declare recording_id: number;
  @Num(null) declare session_id: number;
  @Str(null) declare type: 'instant' | 'archive';

  static api() {
    {
      return {
        async initialize(sessionId: number) {
          await axios.get(`session-recording/${sessionId}/initialize`);
          return true;
        },
        async start(sessionId: number) {
          await axios.get(`/session-recording/start/${sessionId}`);
          return true;
        },
        async stop(sessionId: number) {
          const { data } = await axios.get(
            `/session-recording/stop/${sessionId}`
          );
          const repo = useRepo(SessionRecording);
          repo.save(data);
          return data;
        },
        async terminate(sessionId: number) {
          await axios.get(`session-recording/${sessionId}/terminate`);
          return true;
        },
      };
    }
  }

  static async handleModelMessage(message: {
    payload: SessionRecording;
    method: 'create' | 'update' | 'delete';
  }) {
    if (message.method === 'create') {
      await useRepo(SessionRecording).save(message.payload);
    } else if (message.method === 'update') {
      await useRepo(SessionRecording).save(message.payload);
    } else if (message.method === 'delete') {
      useRepo(SessionRecording).destroy(message.payload.id);
      useRepo(Recording).destroy(message.payload.recording.id);
    }
  }
}
