import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import Employer from "./employer";


export default class PaymentMethod extends Model {

    static entity = 'payment-method';

    @Str('') declare card_brand: string;
    @BelongsTo(() => Employer, 'employer_id') declare employer: Employer | null;
    @Num(null) declare employer_id: number;
    @Num(0) declare expire_month: number;
    @Num(0) declare expire_year: number;
    @Num(null) declare id: number;
    @Str('') declare last_4_digits: string;

    static api() {
        return {
            async getAdmin() {
                const { data } = await axios.get('/payment-method/admin');

                const repo = useRepo(PaymentMethod);
                repo.fresh(data);
            },

            async setupIntentGetAdmin() {
                const { data } = await axios.get('payment-method/setup-intent/admin')
                return data
            },

            async setupIntentPutAdmin(setupIntentId:string) {
                const { data } = await axios.put('/payment-method/setup-intent/admin', { id: setupIntentId });

                const repo = useRepo(PaymentMethod)
                repo.fresh(data)
            }
        }
    }
}