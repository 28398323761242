import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class AssessmentResult extends Model {
  static entity = 'assessment-result';

  @Num(null) declare id: number;
  @Str(null) declare created: number;
  @Str(null) declare modified: number;
}

export class CohortAssessmentResult extends AssessmentResult {
  static entity = 'cohort-assessment-result';

  @Num(null) declare id: number;
  @Str(null) declare created: number;
  @Str(null) declare modified: number;
  @Num(null) declare cohort_id: number;
}
