import { Duration } from 'luxon';
import { Model } from 'pinia-orm';
import { Num, Str } from 'pinia-orm/dist/decorators';

export default class Note extends Model {
  static entity = 'note';

  @Num(null) declare id: number;
  @Str(null) declare body: string;
  @Num(null) declare position: number;

  get timecode() {
    const isoDuration = Duration.fromObject({ seconds: this.position });

    return isoDuration.toFormat('hh:mm:ss');
  }
}
