import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { HasMany, Num, Str } from 'pinia-orm/dist/decorators';

import Recording from './recording';
import SessionRecording from './session_recording';

export default class Session extends Model {
  static entity = 'session';

  @Num(null) declare id: number;
  @Str(null) declare cancel_url: string;
  @Num(null) declare engagement_id: number;
  @Str(null) declare reschedule_url: string;
  @Str(null) declare scheduled: string;
  @HasMany(() => SessionRecording, 'session_id')
  declare recordings: SessionRecording[];

  static api() {
    return {
      async schedule(engagement_id: number, invitee_url: string) {
        await axios.post(`/session/`, {
          engagement_id,
          invitee_url,
        });
      },
      async reschedule(
        session_id: number,
        engagement_id: number,
        invitee_url: string
      ) {
        await axios.put(`/session/${session_id}/`, {
          engagement_id,
          invitee_url,
        });
      },
    };
  }

  get recordingsSorted() {
    return useRepo(SessionRecording)
      .withAllRecursive()
      .where('session_id', this.id)
      .where('type', 'instant')
      .orderBy(function (sessionRecording) {
        return useRepo(Recording).find(sessionRecording.recording_id)?.name;
      })
      .get();
  }
}
