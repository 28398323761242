import { computed } from 'vue';
import { type RouteLocationRaw, useRoute } from 'vue-router';

export const useRedirect = () => {
  const route = useRoute();

  const redirect = computed((): RouteLocationRaw => {
    if (route.query.redirect) {
      return <RouteLocationRaw>route.query.redirect;
    }

    return '/';
  });

  return { redirect };
};
