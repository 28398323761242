import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';

import MeetingAnalysis from './meeting_analysis';
import MeetingExample from './meeting_example';


export default class MeetingObjective extends Model {

    static entity = 'meeting-objective';

    @Num(null) declare analysis_id: number;
    @BelongsTo(() => MeetingAnalysis, 'analysis_id') declare analysis: MeetingAnalysis;
    @Str(null) declare created: string;
    @Str(null) declare description: string;
    @Num(null) declare id: number;
    @Str(null) declare title: string;
}
