import axios from 'axios';
import { DateTime } from 'luxon';
import { Model, useRepo } from 'pinia-orm';
import { Num, Str, Uid } from 'pinia-orm/dist/decorators';

import Coach from './coach';

export default class Invite extends Model {

    static entity = 'invite';

    @Str('') declare created: string;
    @Str('') declare email: string;
    @Str('') declare expires: string;
    @Num(null) declare id: number;
    @Num(null) declare learner_id: number;
    @Uid() declare uuid: number;

    static api() {
        return {

            async detailGetPublic(id: string) {
                const { data } = await axios.get(`/invite/${id}/public`);
                useRepo(Invite).save(data);
            },

            async detailPutPublic(inviteUuid: string, firstName: string, lastName: string, password: string, specialties = null) {
                const data = {
                    first_name: firstName,
                    last_name: lastName,
                    password: password,
                    specialties: specialties,
                };

                return axios.put(`/invite/${inviteUuid}/public`, data);
            },

            // Old
            create(email: string, isAdmin: boolean, assignSkill: boolean, skillId: number | null) {
                if (!assignSkill) {
                    skillId = null;
                }

                const data = {
                    email: email,
                    is_admin: isAdmin,
                    assign_skill: assignSkill,
                    skill_id: skillId,
                };

                return axios.post(`/invite`, data);
            },

            async remove(id: number) {
                await axios.delete(`/invite/${id}/public`);

                const invite = useRepo(Invite).find(id);
                useRepo(Coach).destroy(invite?.coach_id ?? '');
                await useRepo(Invite).destroy(id);
            },
        };
    }

    get expires_date() {
        return DateTime.fromISO(this.expires)
    }
}
