import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { Num } from 'pinia-orm/dist/decorators';


export default class AssignmentAuctionBid extends Model {

    static entity = 'assignment-auction-bid';

    @Num(null) declare assignment_auction_id: number;
    @Num(null) declare coach_id: number;
    @Num(null) declare id: number;

    static api() {
        return {
            async getCoach() {
                const { data } = await axios.get('/assignment-auction-bid/coach');

                const repo = useRepo(AssignmentAuctionBid);
                repo.flush();
                repo.save(data);
            },

            async postCoach(assignmentAuctionId:number) {
                const { data } = await axios.post('/assignment-auction-bid/coach', {
                    assignment_auction_id: assignmentAuctionId
                })

                const repo = useRepo(AssignmentAuctionBid)
                repo.save(data);
            },

            async detailDeleteCoach(assignmentAuctionBidId:number) {
                await axios.delete(`/assignment-auction-bid/${assignmentAuctionBidId}/coach`)

                const repo = useRepo(AssignmentAuctionBid)
                repo.destroy(assignmentAuctionBidId)
            },
        };
    }
}