import axios from 'axios';
import { Model, useRepo } from 'pinia-orm';
import { BelongsTo, HasMany, HasOne, Num, Str } from 'pinia-orm/dist/decorators';

import Assignment from './assignment';
import Coach from './coach';
import Learner from './learner';
import Session from './session';
import Skill from './skill';
import type User from './user';


export default class Engagement extends Model {

    static entity = 'engagement';

    @HasOne(() => Assignment, 'engagement_id') declare assignment: Assignment;
    @BelongsTo(() => Coach, 'coach_id') declare coach: Coach;
    @Num(null) declare coach_id: number;
    @Str(null) declare completed: string;
    @Num(null) declare current_session_id: number;
    @Str(null) declare created: string;
    @Num(null) declare id: number;
    @BelongsTo(() => Learner, 'learner_id') declare learner: Learner;
    @Num(null) declare learner_id: number;
    @HasMany(() => Session, 'engagement_id') declare sessions: Session[];
    @BelongsTo(() => Skill, 'skill_id') declare skill: Skill;
    @Num(null) declare skill_id: number;

    static api() {
        return {
            async list() {
                const { data } = await axios.get('/engagement');
                const repo = useRepo(Engagement);

                repo.save(data);
                const coachList = data.map((datum: Engagement) => datum.coach);
                const learnerList = data.map((datum: Engagement) => datum.learner);

                useRepo(Coach).save(coachList);
                useRepo(Learner).save(learnerList);
            },

            async complete(id: number) {
                await axios.put(`/engagement/${id}/complete`, null);
            },

            async detail(id: number) {
                const { data } = await axios.get(`/engagement/${id}`);

                useRepo(Engagement).save(data);
                useRepo(Coach).save(data.coach);
                useRepo(Learner).save(data.learner);
            },

            async schedulerLink(id: number) {
                const { data } = await axios.get(`/scheduler/link/${id}/`);

                return data;
            },
        };
    }

    static getOtherByUser(user: User, engagement: Engagement) {
        return useRepo(Engagement).withAllRecursive().where((eng) => {
            if (eng.id === engagement.id) {
                return false;
            }

            return (
                useRepo(Coach).find(eng.coach_id)?.user_id === user?.id ||
                useRepo(Learner).find(eng.learner_id)?.user_id === user.id
            );
        }).orderBy('created', 'desc').get();
    }

    static async handleModelMessage(message: {
        payload: Engagement;
        method: 'create' | 'update' | 'delete';
    }) {
        if (message.method === 'update') {
            await useRepo(Engagement).save(message.payload);
        }
    }

    get entity() {
        return Engagement.entity;
    }

    get detailTo() {
        return { name: 'Engagement', params: { engagementId: this.id } };
    }

    get currentSession() {
        return useRepo(Session).withAllRecursive().whereId(this.current_session_id ?? 0).first();
    }

    get key() {
        return `engagement:${this.id}`;
    }

    get name() {
        return this.skill?.name;
    }

    otherShortName(user: User) {
        if (this.coach?.user_id === user.id) {
            return this.learner.user.short_name;
        }

        return useRepo(Coach).find(this.coach_id)?.user.short_name;
    }

    otherUser(user: User) {
        if (this.coach?.user_id === user?.id && this.learner?.user) {
            return this.learner.user;
        } else if (this.coach?.user_id === user?.id) {
            return useRepo(Learner).with('user').find(this.learner.id);
        } else if (this.learner?.user_id === user?.id && this.coach?.user) {
            return this.coach?.user;
        } else if (this.learner?.user_id === user?.id) {
            return useRepo(Coach).with('user').find(this.coach.id);
        }
    }

    get recordingsSorted() {
        return []
    }
}