import { Model } from 'pinia-orm';
import { BelongsTo, Num, Str } from 'pinia-orm/dist/decorators';
import { Duration } from "luxon";

import MeetingReview from './meeting_review';



export default class MeetingRecording extends Model {

    static entity = 'meeting-recording';

    @Str(null) declare created: string;
    @Num(null) declare duration: number;
    @Str(null) declare file: string;
    @Num(null) declare id: number;
    @Num(null) declare review_id: number;
    @BelongsTo(() => MeetingReview, 'review_id') declare review: MeetingReview;

    get duration_string():string {
         return Duration.fromMillis((this.duration * 1000)).toFormat("m'M' s'S'")
     }
}
